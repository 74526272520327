import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import TRANSLATIONS_EN from "./translations_en";
import TRANSLATIONS_ES from "./translations_es";

i18n
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: { translations: TRANSLATIONS_EN },
      es: { translations: TRANSLATIONS_ES },
    },
    fallbackLng: "en",
    debug: process.env.NODE_ENV === 'development',

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    // keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import AppContext, { appReducer, APP_INITIAL_STATE } from './utils/AppContext';

import EntryEditor from './routes/Entry/EntryEditor';
import Dashboard from './routes/Dashboard/Dashboard';
import Signup from './routes/Authentication/Signup';
import Logout from './routes/Authentication/Logout';
import Login from './routes/Authentication/Login';

import LandingPage from './routes/LandingPage/LandingPage';
import AuthRoute from './routes/AuthRoute';
import useTheme from './hooks/useTheme';

import './App.scss';

function App() {

  const [state, dispatch] = useImmerReducer(appReducer, APP_INITIAL_STATE);
  const theme = useTheme(state);

  return (
    <div className='App' data-theme={theme}>
      <AppContext.Provider value={[state, dispatch]}>
        <BrowserRouter>
          <Routes>
            <Route path='/entry/:entryDate' element={<AuthRoute element={<EntryEditor />} />} />
            <Route path='/entry' element={<AuthRoute element={<EntryEditor />} />} />
            <Route path='/dashboard' element={<AuthRoute element={<Dashboard />} />} />
            <Route path='/' element={<AuthRoute noRedirect element={<LandingPage />} />} />

            <Route path='/signup' element={<Signup />} />
            <Route path='/login' element={<Login />} />
            <Route path='/logout' element={<Logout />} />
          </Routes>
        </BrowserRouter>
      </AppContext.Provider>
    </div>
  );
}

export default App;

import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { api } from "../utils/api";
import AppContext from "../utils/AppContext";

export default function AuthRoute({ element, noRedirect = false }) {

  const [state, dispatch] = useContext(AppContext);
  const navigate = useNavigate();

  const { loggedIn, currentUser } = state;

  // If user is not logged in, redirect 
  useEffect(() => {
    if (loggedIn === false && !noRedirect) {
      navigate('/');
    }
  }, [loggedIn, noRedirect, navigate]);

  useEffect(() => {
    const getUser = async () => {
      if (!Cookies.get('__asahi__api_access_token')) {
        dispatch({ type: 'loggedIn/set', payload: false });
      }
      else {
        try {
          const { data } = await api.get('/profile');
          dispatch({ type: 'currentUser/set', payload: data });
        }
        catch {
          // for now simply redirect to landing page:
          dispatch({ type: 'loggedIn/set', payload: false });
        }
      }
    }

    if (!currentUser) {
      getUser();
    }

  }, [currentUser, dispatch]);
  
  return (loggedIn === true || noRedirect) ? element : null;
}
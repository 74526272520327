import Cookies from "js-cookie";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { api, resetAccessToken } from "../../utils/api";
import AppContext from "../../utils/AppContext";

export default function Login() {

  const [, dispatch] = useContext(AppContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const onSignup = async e => {
    e.preventDefault();

    try {
      const { data } = await api.post('/sessions', { session: { email, password }});
      resetAccessToken(data.access_token);
      Cookies.set('__asahi__api_access_token', data.access_token, { expires: 60 });

      const { data: userData } = await api.get('/profile');
      dispatch({ type: 'currentUser/set', payload: userData });
  
      alert('logged in succesfully');
      navigate('/dashboard');
    }
    catch {
      alert('email/password error');
    }
  }

  return (
    <div>
      <h1>Login</h1>
        <div>Password is 'mainichi'</div>
        <form onSubmit={onSignup}>
          <input type='email' placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} />
          <input type='password' placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} />

          <button type='submit'>Login</button>
        </form>
    </div>
  );
}
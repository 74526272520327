import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { api } from "../../utils/api";
import AppContext from "../../utils/AppContext";

export default function Dashboard() {

  const [entries, setEntries] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [, dispatch] = useContext(AppContext);

  useEffect(() => {
    const loadEntries = async () => {
      const { data } = await api.get('/entries');
      setEntries(data);
    }

    loadEntries();
  }, []);

  const toggleFavorite = entry => {
    api.put(`/entries/${entry.ref_id}`, { entry: { favorite: !entry.favorite }});

    const duplicateEntries = JSON.parse(JSON.stringify(entries));
    const e = duplicateEntries.find(e => e.ref_id === entry.ref_id);
    e.favorite = !e.favorite;

    setEntries(duplicateEntries);
  }

  const importEntries = () => {
    // api.post('/entries/create_batch', { entries: EN });
  }

  return (
    <div className='__dashboard'>
      <h1>{ t('Ready to start today’s entry?') }</h1>
      <p>{ t('test') }</p>
      <Link to='/entry'>Today's entry</Link>
      { entries?.map(entry => (
        <div key={entry.ref_id}>
          <button onClick={() => toggleFavorite(entry)}>{ entry.favorite ? '★' : '☆' }</button>
          <Link to={`/entry/${entry.date}`}><div>{ entry.date }</div></Link>
          <div>{ entry.content }</div>
        </div>
      ))}

      <button onClick={importEntries}>Import</button>
      <button onClick={() => navigate('/logout')}>Logout</button>
      <button onClick={() => dispatch({ type: 'theme/set', payload: 'dark'})}>Dark Mode</button>
      <button onClick={() => dispatch({ type: 'theme/set', payload: 'light'})}>Light Mode</button>
      <button onClick={() => dispatch({ type: 'theme/set', payload: null})}>Unset</button>
    </div>
  );
}

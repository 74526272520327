import axios from 'axios';
import Cookies from 'js-cookie';

// Rails expects 'Accept' header, although not required,
// it makes it show the json rendering properly:

export let api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Authorization  : `Bearer ${Cookies.get('__asahi__api_access_token')}`,
    Accept         : 'application/json',
    'Content-Type' : 'application/json',
  }
});

export function resetAccessToken(token) {
  //  instance.defaults.headers.common['Authorization'] = AUTH_TOKEN;
  //! it seems there is a bug that even the default axios instance will show the headers!!
  // strofeApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  
  api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Authorization  : `Bearer ${token}`,
      Accept         : 'application/json',
      'Content-Type' : 'application/json',
    },
  });
}

export function authorizationHeader() {
  return {
    headers: {
      Authorization: `Bearer ${Cookies.get('__asahi__api_access_token')}`
    }
  }
}

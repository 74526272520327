import { useContext } from "react";
import { Link } from "react-router-dom";
import AppContext from "../../utils/AppContext";

import './LandingPage.scss';

export default function LandingPage() {

  const [state] = useContext(AppContext);

  const { loggedIn } = state;

  const renderLogin = () => {
    if (loggedIn) {
      return <Link to='/dashboard'>Continue</Link>
    }
     
    else {
      return (
        <>
          <p><Link to='/login'>Login</Link></p>
          <p><Link to='/signup'>Sign Up</Link></p>
        </>
      );
    }
  }

  return (
    <div className='__landing-page'>
      <h1>WELCOME </h1>
      { renderLogin() }
    </div>
  )
}

import Cookies from "js-cookie";
import { useState } from "react";
import { api, resetAccessToken } from "../../utils/api";

export default function Signup() {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const onSignup = async e => {
    e.preventDefault();

    const { data } = await api.post('/users', { user: { email, password }});
    resetAccessToken(data.access_token);
    Cookies.set('__asahi__api_access_token', data.access_token, { expires: 60 });

    alert('new user created succesfully');
  }

  return (
    <div>
      <h1>Signup</h1>
        <form onSubmit={onSignup}>
          <input type='email' placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} />
          <input type='password' placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} />

          <button type='submit'>Signup</button>
        </form>
    </div>
  );
}
import { useEffect } from 'react';
import { api } from '../../utils/api';
import Cookies from 'js-cookie';

export default function Logout() {
  
  useEffect(() => {
    api.delete('/logout');
    Cookies.remove('__asahi__api_access_token');
  }, []);

  return (
    <div>Logging out...</div>
  )
}

import { createContext } from "react";

const AppContext = createContext();

export const APP_INITIAL_STATE = {
  currentUser : null,
  loggedIn    : null,
  theme       : localStorage.getItem('__mainichi__theme'),
}

export const appReducer = (draft, action) => {
  switch (action.type) {
    case 'currentUser/set':
      draft.currentUser = action.payload;
      draft.loggedIn = true;
      break;

    case 'loggedIn/set':
      draft.loggedIn = action.payload;
      break;

    case 'theme/set':
      draft.theme = action.payload;
      action.payload ? localStorage.setItem('__mainichi__theme', action.payload) : localStorage.removeItem('__mainichi__theme');
      break;

    default:
      console.warn("Non-existing action type:", action.type);
  }
}

export default AppContext;

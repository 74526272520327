function useTheme(state) {
  const { theme } = state;

  const prefersDarkTheme = () => {
    return (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches);
  }

  return theme  || (prefersDarkTheme() ? 'dark' : null);
}

export default useTheme;

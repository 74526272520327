import { useEffect, useMemo, useRef, useState } from 'react';
import { api } from '../../utils/api';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { useParams, useNavigate } from 'react-router';
import { useDebouncedCallback, useThrottledCallback } from 'use-debounce/lib';

import FullscreenIcon from '../../icons/FullscreenIcon';

import './EntryEditor.scss';

function EntryEditor() {

  const { entryDate } = useParams();
  const navigate = useNavigate();

  const [entry, setEntry] = useState('');
  const [content, setContent] = useState('');
  const [wordCount, setWordCount] = useState(0);
  const [saving, setSaving] = useState(false);
  const [hideNavigation, setHideNavigation] = useState(false);
  const [entryHeight, setEntryHeight] = useState(0);
  const [fullscreen, setFullscreen] = useState(false);

  const entryHeightRef = useRef();

  const date = useMemo(() => {
    return entryDate && DateTime.fromISO(entryDate).isValid ? DateTime.fromISO(entryDate) : DateTime.now();
  }, [entryDate]);

  useEffect(() => {
    const loadEntry = async () => {
      // const { data } = await axios.get(`http://localhost:3008/entries?date=${date.toFormat('yyyy-MM-dd')}`);
      const { data } = await api.get(`/entries?date=${date.toFormat('yyyy-MM-dd')}`);

      console.log('data:', data)
      
      if (data?.length > 0) {
        setEntry(data[0]);
        setContent(data[0].content);
        setWordCount(countWords(data[0].content));
      }
    }

    loadEntry();

  }, [date]);

  useEffect(() => {
    if (content) {
      setEntryHeight(entryHeightRef.current.getBoundingClientRect().height);
    }
  }, [content]);

  useEffect(() => {
    const checkFullscreen = () => setFullscreen(document.fullscreenElement ? true : false);

    document.addEventListener('fullscreenchange', checkFullscreen);

    return () => document.removeEventListener('fullscreenchange', checkFullscreen);
  }, []);

  const throttledShowNavigation = useThrottledCallback(e => {
    debouncedSetHideNav.cancel();
    hideNavigation && setHideNavigation(false);
  }, 500);

  useEffect(() => {
    window.addEventListener('mousemove', throttledShowNavigation);
    window.addEventListener('scroll', throttledShowNavigation);

    return () => {
      window.removeEventListener('mousemove', throttledShowNavigation);
      window.removeEventListener('scroll', throttledShowNavigation);
    }
  }, [throttledShowNavigation]);

  const onCreate = async () => {
    const { data } = await api.post('/entries', {
      user_id: 1,
      date: date.toFormat('yyyy-MM-dd'),
    });
    
    data && setEntry(data);
  }

  const saveEntry = async closeEntry => {
    // cancel pending call:
    debouncedSave.cancel();
    setSaving(true);
    
    await api.put(`/entries/${entry.ref_id}`, { user_id: 1, content, ref_id: 'cacaseca' });

    setSaving(false);

    if (closeEntry) {
      navigate('/dashboard');
    }
  }

  const countWords = string => string?.length ? string.split(/\s+\b/).length : 0;

  const debouncedSave = useDebouncedCallback(() => {
    console.log('Auto-saving entry...')
    saveEntry();
  }, 1500, { maxWait: 5000 });
  
  const changeContent = e => {
    !hideNavigation && debouncedSetHideNav(true);

    setContent(e.target.value);
    setWordCount(countWords(e.target.value));
    debouncedSave();
  }

  const debouncedSetHideNav = useDebouncedCallback(value => {
    setHideNavigation(value);
    console.log('hide hav')
  }, 3000, { maxWait: 3000 });

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    }
    else {
      document.exitFullscreen?.();
    }
  }

  return (
    <div className="__entry-editor">
      
      { entry && (
        <>
          <div className={classNames('top-navigation', { hidden: hideNavigation })}>
            <div>
              <div className='date'>{ date.toLocaleString(DateTime.DATE_FULL) }</div>
              <div className='word-count'>{ wordCount } words</div>
            </div>

            <div>
              <button className='save-and-close' disabled={saving} onClick={() => saveEntry(true)}>{ saving ? 'Saving...' : 'Save and exit' }</button>
            </div>
          </div>

          <div className='editor'>
            <div className='editor-container'>
            <textarea autoFocus className='entry entry-text' placeholder='Start writing...' value={content} onChange={changeContent} spellCheck={false} style={{ height: entryHeight }} />
              <div className='entry-height entry-text' ref={entryHeightRef}>{content}</div>
            </div>
          </div>

          <div role='button' className={classNames('toggle-fullscreen', { hidden: hideNavigation })} onClick={toggleFullscreen}>
            <FullscreenIcon />
            <div className='text'>{ fullscreen ? 'Exit fullscreen' : 'Fullscreen' }</div>
          </div>
        </>
      )}
      { !entry && <button onClick={onCreate}>CREATE TODAY's ENTRY</button> }
    </div>
  );
}

export default EntryEditor;
